<template>
  <div id="main" ref="main">
    <keep-alive>
      <header class="header">
        <TopMenu></TopMenu>
      </header>
    </keep-alive>
    <div :style="stylee">
      <keep-alive>
        <aside style="position:absolute;" class="aside">
          <aside-menu> </aside-menu>
        </aside>
      </keep-alive>
      <div class="main">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from "../components/TopMenu";
import AsideMenu from "../components/AsideMenu.vue";
export default {
  name: "Home",
  components: { TopMenu, AsideMenu },
  computed: {
    stylee() {
      return ` height: ${this.$store.state.windowHeight}px`;
    }
  },
  created(){
    if(this.$store.state.LoginAccount.mobile == undefined){
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.aside {
  width: 230px;
  height: 100%;
  overflow-y: auto;
}

.main {
  padding: 15px;
  padding-left: 250px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: #f7f8fa;
  padding-bottom: 5%;
}

::v-deep .el-menu {
  height: 100%;
}

::v-deep .app-aside {
  height: 100%;
}
</style>
