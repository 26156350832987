import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [createPersistedState({
      storage: window.sessionStorage
  })],
  state: {
    windowHeight: 500,
    LoginAccount: {}, //登录信息
    menuActive: "", // 当前导航
    setMenuOpen: "", // 展开的导航
    isAdmin: 0 // 是否管理员
  },
  mutations: {
    setLoginAccount(state, LoginAccount) {
      state.LoginAccount = LoginAccount;
    },
    // setMenuActive(state, menuActive) {
    //   state.menuActive = menuActive;
    // },
    // setMenuOpen(state, menuOpen) {
    //   state.menuOpen = menuOpen;
    // },
    setPermissions(state, permissions) {
      //判断是否是管理员
      if (state.isAdmin == 1) {
        state.permissions = state.optionsId;
      } else {
        state.permissions = permissions;
      }
    },
    setIsAdmin(state, isAdmin) {
      state.isAdmin = isAdmin;
    },
    // 退出登录
    outlogin(state, {}){
      state.LoginAccount = {};
    }
  },
  actions: {}
});

export default store;
