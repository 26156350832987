// import { getEnumList } from "@/api/default";

/**
 * 从一个大对象之中，将需要的字段封装 并return出来
 */
export function getFieldsFromObj(obj, fields) {
  if (!Object.keys(obj).length) {
    throw {
      errMessage: `${JSON.stringify(obj)} ----------  obj 参数异常`
    };
  }
  if (!fields || !fields.length) {
    throw {
      errMessage: `${fields} ----------  fields 参数异常`
    };
  }
  let currentObj = {};
  fields.forEach((item, index) => {
    if (!item || item == "") {
      throw {
        errMessage: `${fields} ----------  fields中第${index}个参数异常`
      };
    }
    currentObj[item] = obj[item];
  });

  return currentObj;
}

// 使用枚举接口获取枚举  并赋值到目标页面的实例上
export async function _getEnumList(param, that) {
  if (!param) {
    throw {
      errMessage: `${JSON.stringify(param)} ----------  param 参数异常`
    };
  }
  if (!param.enum_name_arr.constructor === Array) {
    throw {
      errMessage: `${JSON.stringify(param)} ----------  enum_name_arr 为非数组`
    };
  }
  if (!param.enum_name_arr.length) {
    throw {
      errMessage: `${JSON.stringify(param)} ----------  enum_name_arr 长度错误`
    };
  }
  let res = await getEnumList(param);
  if (res.status == 0) {
    res.data.forEach(item => {
      let key = Object.keys(item)[0];
      that[key] = item[key];
    });
  }
}

/**
 * 去除字符串两端空格
 * @param value string
 */
export function trim(value) {
  return value.replace(/(^\s*)|(\s*$)/g, "");
}

/**
 * 是否为非空字符串
 */
export function nonEmptyString(value) {
  return (
    Object.prototype.toString.call(value) === "[object String]" && value !== ""
  );
}

/**
 * undefined 转 空字符串
 */
export function noneToEmptyStr(value) {
  if (!value || typeof value === "undefined" || value === "undefined") {
    return "";
  }
  return value;
}

//保留两位小数
export function toFix2(value) {
  let value_label = parseFloat(value);
  value_label = Math.round(value_label * 100) / 100;
  let label2 = value_label.toString();
  let pos_decimal = label2.indexOf(".");
  if (pos_decimal < 0) {
    pos_decimal = label2.length;
    label2 += ".";
  }
  while (label2.length <= pos_decimal + 2) {
    label2 += "0";
  }
  return label2;
}

/**
 * 验证规则
 */
const RegEx = {
  mobile: /^((\(\d{3}\))|(\d{3}\-))?1\d{10}$/,
  email: /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((\.[a-zA-Z0-9_-]{2,3}){1,2})$/,
  alphabet: /^[a-z]+$/i,
  number: /^[0-9]+$/,
  float: /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/, // 两位小数
  chinese: /^[\u4e00-\u9fa5]+$/
};
export default RegEx;
