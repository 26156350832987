<template>
  <div class="top-main">
    <div class="top-main-left" style="padding-left: 15px">
      <div class="link"> <img style="width:100%;margin-top: 25%;" src="../assets/image/logo_反白2.png"></img> </div>
      <div class="link" style="margin-left: 10px;font-size: 16px;font-family: FZLanTingHei-R-GBK;font-weight: 400;">
        清耳话声 
      </div>
    </div>
    <div class="top-main-title" style="padding-left: 15px">后台管理系统</div>
    <div class="top-main-right">
      <div class="link linkButton" style="margin-right: 40px;font-size: 16px;" @click="goUserCenter()">
        <!-- 管理员的姓名和电话号码 -->
        <div>{{ $store.state.LoginAccount.realname }} {{ showMobile }}</div>
      </div>
      <div class="link linkButton" style="font-size: 16px;" @click="_logoOut">
        <div style="line-height: 70px;margin-right: 5px;">
          <img style="width:100%" src="@/assets/image/组 2272.png"></img>
        </div>
        <div>退出</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { loginout } from "@/api/user";
export default {
  name: "TopMenu",
  computed: {
    showMobile(){
      let reg=/(\d{3})\d{4}(\d{4})/;
      return this.$store.state.LoginAccount.mobile?this.$store.state.LoginAccount.mobile.replace(reg,"$1****$2"):'';
    }
  },
  methods: {
    async _logoOut() {
      // this.$store.state.LoginAccount = {};
      this.$store.commit("outlogin", {});
      console.log(this.$store.state.LoginAccount);
      if(this.$store.state.LoginAccount.mobile == undefined){
        this.$message.success("退出登录成功");
        this.$router.push({ name: 'login' });
      }else{
        this.$message.error("退出登录失败");
      }
      
      // try{
      //   // 向后端发送请求
      //   let res = await loginout()
      //   // 若返回的status等于0，就跳往登录界面
      //   if (res.status == 0) {
      //     this.$router.push({ name: 'login' })
      //   }
      // }catch(err){
      //   console.log(err.message);
      // }
    },
    // 跳往用户中心界面
    goUserCenter() {
      this.$router.push({ name: 'userCenter' })
    }
  }
}
</script>
  
<style scoped>
.top-main {
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  background: white;
  display: flex;
}
.top-main-left {
  display: flex;
  width: 300px;
  width: 230px;
  background: #390046;
  line-height: 60px;
  color: white;
  font-size: 20px;
}
.top-main-title {
  color: #704d73;
  text-align: center;
  font-size: 18px;
  font-family: FZLanTingHei-R-GBK;
  font-weight: 500;
  line-height: 65px;
}
.top-main-right {
  position: absolute;
  right: 10px;
  display: flex;
  line-height: 60px;
  color: #4a4d4c;
  font-size: 20px;
  margin-right: 20px;
}
.link {
  cursor: pointer;
}
.linkButton {
  display: flex;
  justify-content: center;
  justify-items: center;
}
</style>