<template>
  <div class="app-aside">
    <el-menu background-color="rgba(57, 0, 70, 1)" text-color="#ffffff" active-text-color="#f19500">
      <el-submenu
        :index="val.menu_int + ''"
        v-for="(val, key) in currentModules"
        :key="key">
        <template slot="title">
          <img v-if="val.menu_name == '订单管理'" src='@/assets/image/订单管理.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '文章发布'" src='@/assets/image/文章发布.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '听觉名医录'" src='@/assets/image/听觉名医录.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '后台管理'" src='@/assets/image/后台管理.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '基础维护'" src='@/assets/image/基础维护.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '医生管理'" src='@/assets/image/医生管理.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '用户管理'" src='@/assets/image/用户管理.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <img v-if="val.menu_name == '用户操作'" src='@/assets/image/用户管理.png' style="margin-right: 5px;width: 20px;height: 20px;"></img>
          <span>{{ val.menu_name }}</span>
        </template>

        <el-menu-item-group v-for="(v, k) in val.childList" :key="k">
          <el-menu-item :index="v.menu_int + ''" v-if="!v.childList">
            <router-link class="navbar-item" :to="v.menu_route || ''">{{
              v.menu_name
            }}</router-link>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
    </el-menu>
  </div>
</template>

<script>
import { reqMenuList } from '@/api';
export default {
  name: "asideMenu",
  data: () => ({
    currentModules: []
  }),
  created(){
    this.menuList();
  },
  methods: {
    async menuList(){
      let phone = this.$store.state.LoginAccount.mobile;
      let params = {
        phone: phone
      };
      let result = await reqMenuList(params);
      if(result.code == 200){
        this.currentModules = result.data;
      }
    }
  }
};
</script>

<style scoped>
.title {
  margin-right: 5px;
  width: 20px;
  height: 20px;
}
.navbar-item {
  color: #BBBBBB;
  font-size: 14px;
  text-decoration: inherit;
}
::v-deep .el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background: rgba(255, 255, 255, 0.1) !important;
}
::v-deep .el-menu-item.is-active {
  background: linear-gradient(
    to right,
    rgba(128, 42, 189, 1),
    rgba(108, 0, 118, 1)
  ) !important;
}
::v-deep .el-submenu__title {
  font-size: 15px;
  height: 45px;
  line-height: 45px;
  padding: 0;
}
::v-deep .el-menu-item-group {
  font-size: 13px;
  height: 50px;
  line-height: 50px;
}
::v-deep .el-menu-item-group__title{
  display: none;
}
</style>
