// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import axios from "@/axios";
// import "@/utils/util";
import "@/plugins/element.js";
// froala-editor富文本编辑器的引入
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/languages/zh_cn.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import VueFroala from 'vue-froala-wysiwyg'
Vue.use(VueFroala)
// ------------------------------------------------

Vue.config.productionTip = false;
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
// 添加千位符
Vue.filter("moneyFormat", function(value) {
  if (!value || value + "" == "" || value == "-") {
    return "";
  }
  return "￥" + (value - 0).toFixed(2);
});
/* eslint-disable no-new */
Vue.prototype.$axios = axios;
new Vue({
  el: "#app",
  router,
  store,
  components: { App },
  template: "<App/>"
});
