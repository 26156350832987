import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home";
// test
Vue.use(Router);
const route = new Router({
  routes: [
    {
      path: "/",
      name: 'home',
      component: Home,
      children: [
        {
          path: "/userManage/userList", //用户管理
          name: "userList",
          component: () =>import(/* webpackChunkName: "userList" */ "../views/UserManage/UserList.vue")
        },
        {
          path: "/userManage/userCenter", //个人中心
          name: "userCenter",
          component: () =>import(/* webpackChunkName: "userList" */ "../views/UserManage/UserCenter.vue")
        },
        {
          path: "/userManage/updatePwd", //个人中心
          name: "updatePwd",
          component: () =>import(/* webpackChunkName: "userList" */ "../views/UserManage/updatePwd.vue")
        },
        {
          path: "/userManage/accountList", //账号管理
          name: "accountList",
          component: () =>import(/* webpackChunkName: "accountList" */ "../views/UserManage/AccountList.vue")
        },
        {
          path: "/userManage/userList/userDetail/:userId",
          name: "userDetail",
          component: () =>import(/* webpackChunkName: "userDetail" */ "../views/UserManage/UserDetail.vue")
        },
        {
          path: "/userManage/docList", // 医生管理
          name: "docList",
          component: () =>import(/* webpackChunkName: "DocList" */ "../views/UserManage/DocList.vue")
        },
        {
          path: "/userManage/importDoc", // 导入医生
          name: "importDoctor",
          component: () =>import(/* webpackChunkName: "DocList" */ "../views/UserManage/ImportDoctor.vue")
        },
        {
          path: "/userManage/docList/docDetail/:userId",
          name: "docDetail",
          component: () =>import(/* webpackChunkName: "DocDetail" */ "../views/UserManage/DocDetail.vue")
        },
        {
          path: "/userManage/serviceList", // 代表管理
          name: "serviceList",
          component: () =>import(/* webpackChunkName: "ServiceList" */ "../views/UserManage/ServiceList.vue")
        },
        {
          path: "/userManage/questionList", // 量表列表
          name: "questionList",
          component: () =>import(/* webpackChunkName: "QuestionList" */ "../views/UserManage/QuestionList.vue")
        },
        {
          path: "/userManage/questionDetail/:questionRecordId",
          name: "questionDetail",
          component: () =>import(/* webpackChunkName: "questionDetail" */ "../views/UserManage/QuestionDetail.vue")
        },
        {
          path: "/userManage/customServiceList",
          name: "customServiceList",
          component: () =>import(/* webpackChunkName: "CustomService" */ "../views/UserManage/CustomService.vue")
        },
        {
          path: "/articelManage/page/:category", //文章列表
          name: "articleList",
          component: () =>import(/* webpackChunkName: "ArticleList" */ "../views/ArticleMange/ArticleList.vue")
        },
        {
          path: "/articelManage/edit", //文章编辑
          name: "articleEdit",
          component: () =>import(/* webpackChunkName: "ArticleList" */ "../views/ArticleMange/ArticleEdit.vue")
        },
        {
          path: "/userManage/opreate", //文章编辑
          name: "userOpreate",
          component: () =>import(/* webpackChunkName: "ArticleList" */ "../views/UserManage/Opreate.vue")
        },
        {
          path: "/orderManage/allOrderList", // 订单管理 全部订单
          name: "allOrderList",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/OrderMange/AllOrderList.vue")
        },
        {
          path: "/doctorManage/page",
          name: "doctorPage",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/DoctorManage/Page.vue")
        },
        {
          path: "/doctorManage/edit", 
          name: "doctorEdit",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/DoctorManage/Edit.vue")
        },
        {
          path: "/doctorManage/import", 
          name: "doctorImport",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/DoctorManage/Import.vue")
        },
        {
          path: "/doctorManage/detail",
          name: "doctorDetail",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/DoctorManage/Detail.vue")
        },
        {
          path: "/orderManage/confirmOrderList", // 待确认订单
          name: "confirmOrderList.vue",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/OrderMange/ConfirmOrderList.vue")
        },
        {
          path: "/orderManage/dispatchList", // 发货单
          name: "dispatchList.vue",
          component: () =>import(/* webpackChunkName: "AllOrderList" */ "../views/OrderMange/DispatchList.vue")
        },
        {
          path: "/orderManage/orderDetail/:orderId",
          name: "orderDetail",
          component: () =>import(/* webpackChunkName: "OrderDetail" */ "../views/OrderMange/OrderDetail.vue")
        },
        {
          path: "/orderManage/invoiceList",   // 发票
          name: "invoiceList",
          component: () =>import(/* webpackChunkName: "OrderDetail" */ "../views/OrderMange/InvoiceList.vue")
        },
        {
          path: "/goodsManage/goodsList", // 产品管理
          name: "goodsList",
          component: () =>import(/* webpackChunkName: "GoodsList" */ "../views/GoodsMange/GoodsList.vue")
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: () =>import(/* webpackChunkName: "login" */ "../views/Login.vue")
    }
  ]
});

export default route;
