import requests from './request';

// 打印发货单
export const sendProduct = (params)=>requests({url:'/management/ordersManage/sendProduct', method:'post', data: params});
// 修改设备序列号
export const updateEquipId = (params)=>requests({url:'/management/ordersManage/updateEquipId', method:'post', data: params});
// 查询物流信息
export const reqGetExpressInfo = (params)=>requests({url:'/shunfeng/searchOrder', method:'post', data: params});
// 查询订单是否符合确认要求
export const isCanConfirm = (params)=>requests({url:'/management/ordersManage/isCanConfirm', method:'post', data: params});
// 取消快递订单接口
export const sfCancleOrder = (params)=>requests({url:'/shunfeng/cancleOrder', method:'post', data: params});
// 快递下单接口
export const sfPlaceOrder = (params)=>requests({url:'/shunfeng/placeOrder', method:'post', data: params});
// 新增/编辑用户接口
export const reqAddOrEditAccount = (params)=>requests({url:'/management/backendManage/addoreditmanager', method:'post', data: params});
// 分页查询所有管理员账号的信息
export const reqSelectAllManager = (params)=>requests({url:'/management/backendManage/selectAllManager', method:'post', data: params});
// 导出管理员信息
export const reqExportManager = ()=>requests({url:'/management/backendManage/exportManager', method:'get'});
// 插入新产品
export const reqInsertNewProduct = (params)=>requests({url:'/management/basicMaintence/productManage/insertProduct', method:'post', data: params});
// 分页查询所有产品的信息
export const reqSelectAllProduct = (params)=>requests({url:'/management/basicMaintenance/productManage/selectAllProduct', method:'post', data:params});
// 新增/编辑代表信息
export const reqInsertOrEditDelegate = (params)=>requests({url:'/management/basicMaintence/delegateManage/insertOrEditDelegate', method:'post', data:params});
// 查询所有代表信息
export const reqSelectAllDelegate = (params)=>requests({url:'/management/basicMaintence/delegateManage/selectAllDelegate', method:'post', data: params});
// 导出代表信息
export const reqExportDelegate = ()=>requests({url:'/management/basicMaintence/delegateManage/exportDelegate', method: 'get'});
// 新增/编辑客服
export const reqInsertOrEditService = (params)=>requests({url:'/management/basicMaintence/serviceManage/insertOrEditService', method:'post', data: params});
// 分页查询所有客服
export const reqSelectAllService = (params)=>requests({url:'/management/basicMaintence/serviceManage/selectAllService', method:'post', data: params});
// 导出客服信息
export const reqExportService = ()=>requests({url:'/management/basicMaintence/serviceManage/exportService', method: 'get'});
// 插入新医生
export const reqInsertNewDoctor = (params)=>requests({url:'/management/doctorManage/insertNewDoctor', method:'post', data: params});
// 分页查询所有医生
export const reqSelectAllDoctor = (params)=>requests({url:'/management/doctorManage/selectAllDoctor', method:'post', data: params});
// 导出医生信息
export const reqExportDoctor = ()=>requests({url:'/management/doctorManage/exportDoctor', method: 'get'});
// 查询医生详细信息
export const reqGetDoctorDetail = (params)=>requests({url:'/management/doctorManage/selectDoctorDetail', method:'post', data: params});
// 编辑医生信息
export const reqEditDoctorMsg = (params)=>requests({url:'/management/doctorManage/editDoctorMsg', method:'post', data: params});
// 分页查询所有顾客的基本信息
export const reqSelectAllCustomer = (params)=>requests({url:'/management/customerManage/selectAllCustomer', method:'post', data: params});
// 查询顾客详细信息
export const reqGetCustomerDetail = (params)=>requests({url:'/management/customerManage/getCustomerDetail', method:'post', data: params});
// 导出顾客信息
export const reqExportCustomer = ()=>requests({url:'/management/customerManage/exportCustomer', method:'get'});
// 分页查询所有订单的信息
export const reqSelectAllOrders = (params)=>requests({url:'/management/ordersManage/selectAllOrders', method:'post', data: params});
// 添加订单备注
export const reqAddRemark = (params)=>requests({url:'/management/ordersManage/addRemark', method:'post', data: params});
// 根据订单编号查询订单详细信息
export const reqGetOrdersDetail = (params)=>requests({url:'/management/ordersManage/getOrdersDetail', method:'post', data: params});
// 分页查询所有待确认订单的基本信息
export const reqSelectConfirmOrders = (params)=>requests({url:'/management/ordersManage/selectConfirmOrders', method:'post', data: params});
// 批量确认订单
export const reqBatchConfirmOrders = (params)=>requests({url:'/management/ordersManage/batchConfirmOrders', method:'post', data: params});
// 查询所有发货单
export const reqSelectAllInvoices = (params)=>requests({url:'/management/ordersManage/selectAllInvoices', method:'post', data: params});
// 查询所有发票
export const reqSelectAllInvoice = (params)=>requests({url:'/management/ordersManage/selectAllInvoice', method:'post', data: params});
// 查询所有名医
export const reqSelectAllFamousDoctor = (params)=>requests({url:'/management/famousDoctorManage/selectAllFamousDoctor', method:'post', data: params});
// 查询名医详细信息
export const reqGetFamousDoctorDetail = (params)=>requests({url:'/management/famousDoctorManage/getFamousDoctorDetail', method:'post', data: params});
// 编辑名医信息
export const reqEditFamousDoctor = (params)=>requests({url:'/management/famousDoctorManage/editFamousDoctor', method:'post', data: params});
// 获取全部文章内容
export const reqGetAllArticle = (params)=>requests({url:'/management/articleManage/getAllArticle', method:'post', data: params});
// 新增或编辑文章
export const reqSaveArticle = (params)=>requests({url:'/management/articleManage/saveArticle', method:'post', data: params});
// 根据文章编号查询文章的详细信息
export const reqGetArticleDetail = (params)=>requests({url:'/management/articleManage/getArticleDetail', method:'post', data: params});
// 富文本中插入图片
export const reqUploadImgInArticle = (params)=>requests({url:'/management/articleManage/uploadImgInArticle', method:'post', data: params});
// 删除文章
export const reqDeleteArticles = (params)=>requests({url:'/management/articleManage/deleteArticles', method:'post', data: params});
// 登录
export const reqLogin = (params)=>requests({url:'/management/login', method:'post', data: params});
// 登录时发送验证码
export const reqSendCode = (params)=>requests({url:'/management/sendCode', method:'post', data: params});
// 查询左侧菜单列表
export const reqMenuList = (params)=>requests({url:'/management/menuList', method:'post', data: params});
// 标记开票 openInvoice
export const openInvoice = (params)=>requests({url:'/management/ordersManage/openInvoice', method:'post', data: params});
// 获取量表列表 questionPage
export const questionPage = (params)=>requests({url:'/management/questionManage/questionPage', method:'post', data: params});
// 获取量表详情 
export const questionDetail = (params)=>requests({url:'/management/questionManage/questionDetail', method:'post', data: params});
// 获取问卷题目
export const questionList = (params)=>requests({url:'/customer/measurement/getQuestionList', method:'post', data: params});
// 删除管理员
export const deleteManager = (params)=>requests({url:'/management/backendManage/deleteManager', method:'post', data: params});
// 上下架产品
export const upDownProduct = (params)=>requests({url:'/management/basicMaintenance/productManage/upDownProduct', method:'post', data: params});
// 删除产品 
export const deleteProduct = (params)=>requests({url:'/management/basicMaintenance/productManage/deleteProduct', method:'post', data: params});
// 启用停用医生
export const startEndDoctor = (params)=>requests({url:'/management/doctorManage/startEndDoctor', method:'post', data: params});
// 删除医生 
export const deleteDoctor = (params)=>requests({url:'/management/doctorManage/deleteDoctor', method:'post', data: params});
// 特殊身份的设置
export const reqSetSpecialStatus = (params)=>requests({url:'/new/setSpecialStatus', method:'post', data: params});
// 基础设置查询
export const getBasicSet = ()=>requests({url:'/new/getBasicSet', method:'get'});
// 基础设置更改
export const basicSet = (params)=>requests({url:'/new/basicSet', method:'post', data: params});

