<template>
  <div id="app" ref="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    // 在页面加载时读取sessionStorage里的状态信息
    if (localStorage.getItem('store')) {
        this.$store.replaceState(
            Object.assign(
                {},
                this.$store.state,
                JSON.parse(localStorage.getItem('store'))
            )
        )
    }
    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    // beforeunload事件在页面刷新时先触发
    window.addEventListener('beforeunload', () => {
        localStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  mounted() {
    let height = this.$refs.app.offsetHeight;
    this.$store.state.windowHeight = height;
    window.addEventListener("resize", this.handleWindow, true);
    // new SCPPrint();
  },
  methods: {
    handleWindow() {
      let height = this.$refs.app.offsetHeight;
      this.$store.state.windowHeight = height;
    }
  }
};
</script>

<style lang="scss">
#app {
  background: #f7f8fa;
}
@import url(./assets/style/common.scss);
</style>
