// 对于axios进行二次封装
import axios from 'axios'
// import store from "../store";

// 1. 利用axios对象的方法create创建一个axios实例
const requests = axios.create({
    // 配置对象
    // 基础路径
    baseURL:'/api',
    // 代表请求超时的时间5s
    timeout:5000,
});

// 请求拦截器：在发请求之前，请求拦截器可以监测到，可以在请求发出去之前做一些事情
// requests.interceptors.request.use((config)=>{
//     // config是一个配置对象，header请求头
//     // 向服务器请求用户信息的时候，需要携带token
//     // if(store.state.customer.customerInfo.token!=''){
//     //     config.headers.Authorization=store.state.customer.customerInfo.token;
//     // }
//     // return config;
// })

// 响应拦截器
requests.interceptors.response.use((res)=>{
    // 成功的回调函数
    return res.data;
},(error)=>{
    // 响应失败的回调函数
    return Promise.reject(new Error('fail'));
})

// 对外暴露
export default requests;