import axios from "axios";
import qs from "qs";
import { noneToEmptyStr } from "@/utils/util";
import router from "@/router";
if (process.env.NODE_ENV === "development") {
  // dev url
  // axios.defaults.baseURL = "/dev-api/";
  axios.defaults.baseURL = "/api/";

} else {
  // build url
  axios.defaults.baseURL = "/api/";
}
console.log(process.env.NODE_ENV);
// 返回错误码处理
function errorCodeHandle(codeStatus) {
  switch (codeStatus) {
    case 401:
      router.push({ name: "login" });
  }
}
// 表单请求
axios.formRequest = axios.create({
  baseUrl: axios.defaults.baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  },
  transformRequest: [
    function(data) {
      if (Object.prototype.toString.call(data) === "[object Object]") {
        for (const k in data) {
          data[k] = noneToEmptyStr(data[k]);
        }
      }
      return qs.stringify(data, { arrayFormat: "indices" });
    }
  ]
});
console.log(axios.defaults.baseURL);
axios.formRequest.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
);
axios.formRequest.interceptors.response.use(
  response => response.data,
  error => {
    if (error.response) {
      errorCodeHandle(error.response.status);
    }
    return Promise.reject(error);
  }
);
// 导入表格
axios.uploadFile = axios.create({
  baseUrl: axios.defaults.baseURL,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});
axios.uploadFile.interceptors.response.use(
  function(response) {
    return response.data;
  },
  function(error) {
    if (error.response) {
      errorCodeHandle(error.response.status);
    }
    return Promise.reject(error);
  }
);
// 导出表格
axios.exportExcel = axios.create({
  baseUrl: axios.defaults.baseURL,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
  },
  transformRequest: [
    function(data) {
      if (Object.prototype.toString.call(data) === "[object Object]") {
        for (let k in data) {
          data[k] = noneToEmptyStr(data[k]);
        }
      }
      return qs.stringify(data, { arrayFormat: "indices" });
    }
  ]
});
export default axios;
